export const BASE_API_URL = '/api/ue/'

export const API_UE = Object.freeze({
  LOCATION: 'location_id.php',
  LOCATION_HISTORY: 'user-express-campaign-history.php',
  OPENING_HOURS: 'location-opening-hours.php',
  TMP_OPENING_HOURS: 'tmp-location-opening-hours.php',
  EXCEPTIONAL_HOURS: 'location-exceptional-opening-hours.php',
  TMP_EXCEPTIONAL_HOURS: 'tmp-location-exceptional-opening-hours.php',
  CREATE_CAMPAIGN: 'user-express-campaign.php',
  OH_SUBGROUPS: 'opening-hours-subgroups.php',
})
