import Vue from 'vue'
import Vuex from 'vuex'
import location from './modules/location.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    location,
  },
  strict: process.env.NODE_ENV !== 'production',
})
