import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: !!(localStorage.getItem('dark_theme') * 1) || false,
    themes: {
      light: {
        primary: '#4e5cec',
        textfield: '#fff',
      },
      dark: {
        primary: '#4e5cec',
        textfield: '#1e1e1e',
      },
    },
    options: {
      customProperties: true,
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      },
      minifyTheme,
    },
  },
  breakpoint: {
    thresholds: {
      xs: 768,
      sm: 1024,
      md: 1280,
      lg: 1920,
    },
    mobileBreakpoint: 'sm',
  },
})
