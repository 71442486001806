import axios from 'axios'

const api = axios.create({
  baseUlr: '/',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const sendGetRequest = async (url, config = null) => {
  const resp = await api.get(url, config)
  return resp.data
}

export const sendPutRequest = async (url, data, config = null) => {
  const resp = await api.put(url, data, config)
  return resp.data
}

export const sendPostRequest = async (url, data, config = null) => {
  const resp = await api.post(url, data, config)
  return resp.data
}
