import { sendGetRequest, sendPutRequest, sendPostRequest } from '@/services/api.service'
import { BASE_API_URL, API_UE } from '@/config/api.config'

export const getApiLocation = (token, ltoken) => {
  return sendGetRequest(`${BASE_API_URL}${API_UE.LOCATION}?token=${token}&ltoken=${ltoken}`)
}

export const getApiLocationHistory = (token, ltoken) => {
  return sendGetRequest(`${BASE_API_URL}${API_UE.LOCATION_HISTORY}?token=${token}&ltoken=${ltoken}`)
}

export const postApiLocationHistory = (token, ltoken, data) => {
  return sendPostRequest(
    `${BASE_API_URL}${API_UE.LOCATION_HISTORY}?token=${token}&ltoken=${ltoken}&administratorId=${data.administratorId}&id=${data.id}`,
    {
      ...data,
      status: 'processing',
      comment: '',
    }
  )
}

export const getApiOpeningHours = (token, ltoken) => {
  return sendGetRequest(`${BASE_API_URL}${API_UE.OPENING_HOURS}?token=${token}&ltoken=${ltoken}`)
}

export const getApiExceptionalHours = (token, ltoken) => {
  return sendGetRequest(`${BASE_API_URL}${API_UE.EXCEPTIONAL_HOURS}?token=${token}&ltoken=${ltoken}`)
}

export const getApiLocationSubGroups = (token, ltoken, groupId) => {
  return sendGetRequest(`${BASE_API_URL}${API_UE.OH_SUBGROUPS}?token=${token}&ltoken=${ltoken}&groupId=${groupId}`)
}

export const putApiOpeningHours = (token, ltoken, data) => {
  return sendPutRequest(`${BASE_API_URL}${API_UE.TMP_OPENING_HOURS}?token=${token}&ltoken=${ltoken}`, data)
}

export const putApiExceptionalHours = (token, ltoken, data) => {
  return sendPutRequest(`${BASE_API_URL}${API_UE.TMP_EXCEPTIONAL_HOURS}?token=${token}&ltoken=${ltoken}`, data)
}

export const putApiCreateCampaign = email => {
  return sendPutRequest(`${BASE_API_URL}${API_UE.CREATE_CAMPAIGN}`, { email })
}
