import {
  getApiLocation,
  getApiLocationHistory,
  getApiOpeningHours,
  getApiExceptionalHours,
  getApiLocationSubGroups,
  putApiOpeningHours,
  putApiExceptionalHours,
  postApiLocationHistory,
  putApiCreateCampaign,
} from '@/services/location.service'

const SET_TOKENS = 'SET_TOKENS'
const SET_LOCATION = 'SET_LOCATION'
const SET_LOCATION_HISTORY = 'SET_LOCATION_HISTORY'
const SET_LOCATION_OPENING_HOURS = 'SET_LOCATION_OPENING_HOURS'
const SET_LOCATION_SUB_GROUPS = 'SET_LOCATION_SUB_GROUPS'
const CREATE_LOCATION_OPENING_HOURS = 'CREATE_LOCATION_OPENING_HOURS'
const UPDATE_LOCATION_OPENING_HOURS = 'UPDATE_LOCATION_OPENING_HOURS'
const DELETE_LOCATION_OPENING_HOURS = 'DELETE_LOCATION_OPENING_HOURS'
const SET_LOCATION_EXCEPTIONAL_HOURS = 'SET_LOCATION_EXCEPTIONAL_HOURS'
const CREATE_LOCATION_EXCEPTIONAL_HOURS = 'CREATE_LOCATION_EXCEPTIONAL_HOURS'
const UPDATE_LOCATION_EXCEPTIONAL_HOURS = 'UPDATE_LOCATION_EXCEPTIONAL_HOURS'
const DELETE_LOCATION_EXCEPTIONAL_HOURS = 'DELETE_LOCATION_EXCEPTIONAL_HOURS'

const state = () => {
  return {
    token: null,
    ltoken: null,
    location: null,
    locationHistory: null,
    locationSubGroups: [],
    locationOpeningHours: [],
    locationExceptionalHours: [],
    tmpLocationOpeningHours: [],
    tmpLocationExceptionalHours: [],
  }
}

const mutations = {
  [SET_TOKENS]: (state, tokens) => {
    state.token = tokens.token
    state.ltoken = tokens.ltoken
  },
  [SET_LOCATION]: (state, location) => {
    state.location = location
  },
  [SET_LOCATION_HISTORY]: (state, history) => {
    state.locationHistory = history
  },
  [SET_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.locationOpeningHours = openingHours.map(oh => ({
      ...oh,
      matchId: oh.matchId ? oh.matchId : oh.action !== 'insert' ? oh.id : null,
    }))
    state.tmpLocationOpeningHours = [...state.locationOpeningHours]
  },
  [SET_LOCATION_SUB_GROUPS]: (state, locationSubGroups) => {
    state.locationSubGroups = locationSubGroups
  },
  [CREATE_LOCATION_OPENING_HOURS]: (state, openingHour) => {
    state.locationOpeningHours.push({
      ...openingHour,
      tmpId: Date.now(),
      action: 'insert',
      position: state.locationOpeningHours.filter(oh => oh.action !== 'delete').length + 1,
      locationId: state.location.id,
    })
  },
  [UPDATE_LOCATION_OPENING_HOURS]: (state, { openingHour, isSubGroup = false }) => {
    if (isSubGroup) {
      state.locationOpeningHours = state.locationOpeningHours
        .filter(oh => oh.action !== 'insert')
        .map(oh => ({ ...oh, action: 'delete' }))
      state.locationOpeningHours.push({
        ...openingHour,
        tmpId: Date.now(),
        action: 'insert',
        position: '1',
        locationId: state.location.id,
      })
    } else if (!isSubGroup) {
      state.locationOpeningHours = state.locationOpeningHours.map(oh => {
        if ((oh.id && oh.id === openingHour.id) || (oh.tmpId && oh.tmpId === openingHour.tmpId)) {
          return {
            ...openingHour,
            matchId: openingHour.matchId
              ? openingHour.matchId
              : openingHour.action !== 'insert'
              ? openingHour.id
              : null,
            action: openingHour.action ? openingHour.action : 'update',
            locationId: state.location.id,
          }
        }
        return oh
      })
    }
  },
  [DELETE_LOCATION_OPENING_HOURS]: (state, openingHour) => {
    if (openingHour.action === 'insert') {
      state.locationOpeningHours = state.locationOpeningHours.filter(
        oh => (oh.id && oh.id !== openingHour.id) || (oh.tmpId && oh.tmpId !== openingHour.tmpId)
      )
    } else {
      state.locationOpeningHours = state.locationOpeningHours.map(oh => {
        if ((oh.id && oh.id === openingHour.id) || (oh.tmpId && oh.tmpId === openingHour.tmpId)) {
          return {
            ...openingHour,
            matchId: openingHour.matchId ? openingHour.matchId : openingHour.id,
            action: 'delete',
            locationId: state.location.id,
          }
        }
        return oh
      })
    }
  },
  [SET_LOCATION_EXCEPTIONAL_HOURS]: (state, exceptionalHours) => {
    state.locationExceptionalHours = exceptionalHours.map(eh => ({
      ...eh,
      matchId: eh.matchId ? eh.matchId : eh.action !== 'insert' ? eh.id : null,
    }))
    state.tmpLocationExceptionalHours = [...state.locationExceptionalHours]
  },
  [CREATE_LOCATION_EXCEPTIONAL_HOURS]: (state, exceptionalHour) => {
    state.locationExceptionalHours.push({
      ...exceptionalHour,
      tmpId: Date.now(),
      action: 'insert',
      locationId: state.location.id,
    })
  },
  [UPDATE_LOCATION_EXCEPTIONAL_HOURS]: (state, exceptionalHour) => {
    state.locationExceptionalHours = state.locationExceptionalHours.map(eh => {
      if ((eh.id && eh.id === exceptionalHour.id) || (eh.tmpId && eh.tmpId === exceptionalHour.tmpId)) {
        return {
          ...exceptionalHour,
          matchId: exceptionalHour.matchId
            ? exceptionalHour.matchId
            : exceptionalHour.action !== 'insert'
            ? exceptionalHour.id
            : null,
          action: exceptionalHour.action ? exceptionalHour.action : 'update',
          locationId: state.location.id,
        }
      }
      return eh
    })
  },
  [DELETE_LOCATION_EXCEPTIONAL_HOURS]: (state, exceptionalHour) => {
    if (exceptionalHour.action === 'insert') {
      state.locationExceptionalHours = state.locationExceptionalHours.filter(
        eh => (eh.id && eh.id !== exceptionalHour.id) || (eh.tmpId && eh.tmpId !== exceptionalHour.tmpId)
      )
    } else {
      state.locationExceptionalHours = state.locationExceptionalHours.map(eh => {
        if ((eh.id && eh.id === exceptionalHour.id) || (eh.tmpId && eh.tmpId === exceptionalHour.tmpId)) {
          return {
            ...exceptionalHour,
            matchId: exceptionalHour.matchId ? exceptionalHour.matchId : exceptionalHour.id,
            action: 'delete',
            locationId: state.location.id,
          }
        }
        return eh
      })
    }
  },
}

const actions = {
  saveTokens({ commit }, payload) {
    commit(SET_TOKENS, payload)
  },
  async getLocation({ state, commit }) {
    const { data } = await getApiLocation(state.token, state.ltoken)
    commit(SET_LOCATION, data[0])
  },
  async getLocationHistory({ state, commit }) {
    const { data } = await getApiLocationHistory(state.token, state.ltoken)
    commit(SET_LOCATION_HISTORY, data)
  },
  async saveLocationHistory({ state, commit }) {
    const { data } = await postApiLocationHistory(state.token, state.ltoken, state.locationHistory)
    commit(SET_LOCATION_HISTORY, data)
  },
  async getOpeningHours({ state, commit }) {
    const { data } = await getApiOpeningHours(state.token, state.ltoken)
    commit(SET_LOCATION_OPENING_HOURS, data.results)
  },
  async getlocationSubGroups({ state, commit }, clientId) {
    const { data } = await getApiLocationSubGroups(state.token, state.ltoken, clientId)
    commit(SET_LOCATION_SUB_GROUPS, data.results)
  },
  updateOpeningHoursSubGroup: ({ commit }, ohSubGroup) => {
    const openingHour = { hours: ohSubGroup.hours, openingHoursSubGroupId: ohSubGroup.id }
    commit(UPDATE_LOCATION_OPENING_HOURS, { openingHour, isSubGroup: true })
  },
  updateOpeningHours: ({ commit }, openingHour) => {
    commit(UPDATE_LOCATION_OPENING_HOURS, { openingHour })
  },
  createOpeningHours: ({ commit }, openingHour) => {
    commit(CREATE_LOCATION_OPENING_HOURS, openingHour)
  },
  deleteOpeningHours: ({ commit }, openingHour) => {
    commit(DELETE_LOCATION_OPENING_HOURS, openingHour)
  },
  async saveOpeningHours({ state, commit }) {
    const { data } = await putApiOpeningHours(state.token, state.ltoken, state.locationOpeningHours)
    commit(SET_LOCATION_OPENING_HOURS, data.results)
  },
  async getExceptionalHours({ state, commit }) {
    const { data } = await getApiExceptionalHours(state.token, state.ltoken)
    commit(SET_LOCATION_EXCEPTIONAL_HOURS, data.results)
  },
  updateExceptionalHours: ({ commit }, exceptionalHour) => {
    commit(UPDATE_LOCATION_EXCEPTIONAL_HOURS, exceptionalHour)
  },
  createExceptionalHours: ({ commit }, exceptionalHour) => {
    commit(CREATE_LOCATION_EXCEPTIONAL_HOURS, exceptionalHour)
  },
  deleteExceptionalHours: ({ commit }, exceptionalHour) => {
    commit(DELETE_LOCATION_EXCEPTIONAL_HOURS, exceptionalHour)
  },
  async saveExceptionalHours({ state, commit }) {
    const { data } = await putApiExceptionalHours(state.token, state.ltoken, state.locationExceptionalHours)
    commit(SET_LOCATION_EXCEPTIONAL_HOURS, data.results)
  },
  async createCampaign(_, email) {
    await putApiCreateCampaign(email)
  },
}

const getters = {
  existingOpeningHours: state => state.locationOpeningHours.filter(oh => oh.action !== 'delete'),
  existingExceptionalHours: state => state.locationExceptionalHours.filter(eh => eh.action !== 'delete'),
  isLocationWithGroup: state =>
    state.location.openingHoursGroupId &&
    state.location.openingHoursSubGroupId &&
    state.location.openingHoursGroupExcluded !== '1',
  currentOhSubGroupId: state => {
    const currentOpeningHour = state.locationOpeningHours.find(oh => oh.action === 'insert')
    return currentOpeningHour?.openingHoursSubGroupId
      ? currentOpeningHour.openingHoursSubGroupId
      : state.location.openingHoursSubGroupId
  },
  currentTmpOhSubGroupId: state =>
    state.tmpLocationOpeningHours.find(toh => toh.action === 'insert')?.openingHoursSubGroupId,
  hasTemporaryOh: state => state.tmpLocationOpeningHours.find(oh => oh.action === 'insert') !== undefined,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
